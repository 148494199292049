<template>
  <container>
    <v-card :loading="loading">
      <v-card-title>
        {{ $capitalize($tc("model.scopes_scopes_title")) }}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-row>
        <v-col cols="12" sm="7" md="6" lg="4" xl="3">
          <v-card-text class="mt-5">
            <v-text-field
              v-model="filter"
              :placeholder="
                $capitalize($tc('model.scopes_scope_search'))
              "
              prepend-inner-icon="mdi-magnify"
              outlined
            ></v-text-field>
          </v-card-text>
        </v-col>
        <v-col class="d-flex align-center mr-5">
          <v-spacer></v-spacer>
          <v-btn
            v-if="$store.getters['auth/getScope']('auth.scopes.add')"
            @click="handleNewScope()"
            color="primary"
          >
            <v-icon left>mdi-plus</v-icon>{{ $capitalize($tc("model.scopes_new_scope")) }}
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table 
        :headers="headers" 
        :items="scopes" 
        :search="filter"
      >
        <template v-slot:header.name>
          {{ $capitalize($tc("model.scopes_name", 1)) }}
        </template>

        <template v-slot:header.path>
          {{ $capitalize($tc("model.scopes_path", 1)) }}
        </template>

        <template v-slot:header.description>
          {{ $capitalize($tc("model.scopes_description", 1)) }}
        </template>

        <template v-slot:item.path="{ item }">
          <v-chip label small color="secondary">{{ item.path }}</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            @click="handleEditScope(item)"
            v-if="$store.getters['auth/getScope']('auth.scopes.edit')"
            icon
          >
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-btn
            @click="handleDeleteScope(item)"
            v-if="$store.getters['auth/getScope']('auth.scopes.delete')"
            icon
            :loading="item.id === delete_id"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title>
          {{ $capitalize($tc("model.scopes_form_scope")) }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <ValidationObserver ref="form" v-slot="{ }">
          <v-form @submit.prevent="handleSubmitForm()">
            <v-card-text>
              <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                <v-text-field v-model="form.name" name="name" :label="$capitalize($tc('model.scopes_name'))" :error-messages="errors"></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="path" rules="required" v-slot="{ errors }">
                <v-text-field
                  v-model="form.path"
                  name="path"
                  :label="$capitalize($tc('model.scopes_path'))"
                  placeholder="auth.users.read"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="description" rules="required" v-slot="{ errors }">
                <v-textarea
                  v-model="form.description"
                  name="description"
                  :label="$capitalize($tc('model.scopes_description'))"
                  :error-messages="errors"
                ></v-textarea>
              </ValidationProvider>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="dialog = false" text>{{ $capitalize($tc("model.scopes_button_cancel")) }}</v-btn>
              <v-btn type="submit" color="primary" :loading="submitting">{{ $capitalize($tc("model.scopes_button_save")) }}</v-btn>
            </v-card-actions>
          </v-form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </container>
</template>

<script>
import { index, destroy, update, store } from "../services/scopes";

export default {
  name: "scopes",
  data: () => ({
    scopes: [],
    dialog: false,
    loading: false,
    form: {},
    submitting: false,
    delete_id: null,
    filter: "",
    headers: [
      {
        text: "Nome",
        value: "name",
      },
      {
        text: "Chave",
        value: "path",
      },
      {
        text: "Descrição",
        value: "description",
      },
      {
        text: "",
        value: "actions",
      },
    ],
  }),
  methods: {
    async getScopes() {
      this.loading = true;
      try {
        this.scopes = await index(this.filter);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async handleSubmitForm() {
      const validate = await this.$refs.form.validate();
      if (!validate) return;

      this.submitting = true;
      try {
        if (this.form.id) {
          await update(this.form.id, this.form);
        } else {
          await store(this.form);
        }
        this.dialog = false;
        await this.getScopes();
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: `Falha ao submeter formulário`,
          color: "red",
        });
      } finally {
        this.submitting = false;
      }
    },
    async handleDeleteScope(item) {
      if (confirm("Tem certeza que deseja excluir?")) {
        try {
          this.delete_id = item.id;
          await destroy(item.id);

          this.scopes = this.scopes.filter((scope) => scope.id !== item.id);
        } catch (error) {
          this.$store.commit("sendMessage", {
            text: `Falha ao excluir item`,
            color: "red",
          });
        } finally {
          this.delete_id = null;
        }
      }
    },
    handleEditScope(scope) {
      if (this.$store.getters["auth/getScope"]("auth.scopes.edit")) {
        this.form = { ...scope };
        this.dialog = true;
      }
    },
    handleNewScope() {
      this.form = {};
      this.dialog = true;
      if (this.$refs.form) this.$refs.form.reset();
    },
  },
  created() {
    this.getScopes();
  },
};
</script>