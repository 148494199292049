import Vue from "vue";
const http = Vue.prototype.$http;

export async function index(filter = "") {
  const response = await Vue.prototype.$http.get("/scopes", {
    params: {
      filter
    }
  });

  return response.data;
}

export async function store(payload) {
  const response = await Vue.prototype.$http.post("/scopes", payload);

  return response.data;
}

export async function update(id, payload) {
  const response = await Vue.prototype.$http.put("/scopes/" + id, payload);

  return response.data;
}

export async function destroy(id) {
  const response = await Vue.prototype.$http.delete("/scopes/" + id);

  return response;
}
